import HeroSection from "../components/heroSection"
import Layout from "../components/layout"
import MissionVision from "../components/missionVision"
import React from "react"

function HomePage() {
  return (
    <Layout route="/">
      <HeroSection />
      <MissionVision />
    </Layout>
  )
}

export default HomePage
