// import Logo from "../images/logo-blue.png"
import {StaticImage} from 'gatsby-plugin-image'
import "../styles/heroSection.css"
import React from "react"

function HeroSection() {
  return (
    <>
      <div id="hero" className="grid grid-cols-12 bg-dark">
        <div className="glass col-start-2 col-span-10 ">
          <div className="grid grid-rows-1">
            <div className="grid grid-cols-12">
              <div className="col-span-10 col-start-2 lg:col-span-4 lg:col-start-2 imgCol">
              <StaticImage
                        src="../images/logo-blue.png"
                        quality={100}
                        placeholder="blurred"
                        formats={["AUTO", "WEBP", "AVIF"]}
                        alt="logo"
                        className="inset-0"
                       
                      />
              </div>
              <div className="col-start-2 col-span-10  lg:col-span-8 lg:col-start-6">
                {" "}
                <p className="h1"> ANTARYAMI</p>
                <p className="h2">ONLINE YOGA CONSERVATORY</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default HeroSection
