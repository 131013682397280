import "../styles/missionVision.css"
import React from "react"

function MissionElement() {
  return (
    <div>
      <div id="Missionscreen">
        <div className="grid grid-rows-3">
          <div className="grid grid-cols-12">
            <div className="about col-start-2 col-span-10">
              <h2>ABOUT US</h2>
              <p>
                Antaryami is an online yoga conservatory that offers a variety
                of programmes to help people enhance their physical and mental
                wellness. Under the tutelage of academically competent trainers,
                "Antaryami" offers a distinct opportunity on the webspace to
                acquire and integrate the ancient knowledge of life balance -
                "yoga." Antaryami's panel also includes well-experienced
                Ayurvedic doctors and counsellors to promote participants'
                well-being.
              </p>
            </div>
          </div>
          <div className="vision grid grid-cols-12">
            <div className="col-start-2 col-span-10">
              <h2>VISION</h2>
              <p>
                Antaryami's primary idea and vision is to touch all horizons of
                wellness, physical, mental, and emotional health to create a
                uniqe opportunity to explore new dimensions of life. Antaryami
                will be your online sanctuary to unwind and rejuvenate, to
                settle your mind, and, most importantly, to discover the best in
                yourself.
              </p>
            </div>
          </div>
          <div className="grid grid-cols-12">
            <div className="mission col-start-2 col-span-10">
              <h2>MISSION</h2>
              <p>
                In this difficult time of confusion and tension, we are resolved
                to create individuals who think, speak, and act in favour of
                harmony and peace. Antaryami is dedicated to disseminating the
                ancient message of one world family.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MissionElement
